import { type CreditCard } from '@/services/CreditCards/types'
import { type PaymentMethod } from '@/services/PaymentMethods/types'

export const toPaymentMethod = (creditCard: CreditCard): PaymentMethod => {
  return {
    ...creditCard,
    paymentMethodId: `credit_card-${String(creditCard.id)}`,
    option: 'credit_card',
  }
}
