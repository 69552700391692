export const NOTE_CHAR_LIMIT = 600
export const PRODUCT_NOTE_CHAR_LIMIT = 400
export const BOGO_QUANTITY_INCREMENT = 2
export const DELIVERY_TYPES = {
  STANDARD: 'standard',
  PICKUP: 'pickup',
}

export enum ORDER_STATUS {
  DELIVERED = 'delivered',
  CREATED = 'created',
  OPEN = 'open',
  CLAIMED = 'claimed',
  SHOPPING = 'shopping',
  PROCESSED = 'processed',
  CANCELLED = 'cancelled',
  ABANDONED = 'abandoned',
  READY_FOR_PICKUP = 'ready for pickup',
  RETURNED = 'returned',
}

export type OrderStatus = `${ORDER_STATUS}`

// ------ SEARCH CONSTANTS --------

export const SORT_BY_PARAMS = {
  PRICE_LOW_TO_HIGH: 'price_low_to_high',
  PRICE_HIGH_TO_LOW: 'price_high_to_low',
  RECENCY: 'recency',
}

export const SORT_BY_TEXTS = {
  BEST_MATCH: 'Best match',
  MOST_POPULAR: 'Most popular',
  MOST_RECENT: 'Most recent',
  MOST_FREQUENT: 'Most frequent',
  PRICE_LOWEST_FIRST: 'Price: lowest first',
  PRICE_HIGHEST_FIRST: 'Price: highest first',
}

export const SEARCH_SORT_BY_OPTIONS = {
  [SORT_BY_PARAMS.PRICE_LOW_TO_HIGH]: SORT_BY_TEXTS.PRICE_LOWEST_FIRST,
  [SORT_BY_PARAMS.PRICE_HIGH_TO_LOW]: SORT_BY_TEXTS.PRICE_HIGHEST_FIRST,
}
export const BUY_AGAIN_SORT_BY_OPTIONS = {
  [SORT_BY_PARAMS.RECENCY]: SORT_BY_TEXTS.MOST_RECENT,
}
export const SORT_BY_OPTIONS = {
  ...SEARCH_SORT_BY_OPTIONS,
  ...BUY_AGAIN_SORT_BY_OPTIONS,
}

export enum SEARCH_FILTER_TYPE {
  CATEGORY = 'categories',
  ACTIVE_DEALS = 'active_deals',
  BRAND = 'brands',
  SORT_BY = 'sort_by',
  ON_SALE = 'on_sale',
  STORE = 'store_id',
  NUTRITION = 'nutrition',
  DIETS = 'diets',
}

export const SEARCH_FACETS = {
  ACTIVE_DEALS: 'active_deals',
  BRAND: 'brand_name',
  CATEGORIES: 'categories',
  H1_CATEGORIES: 'h1categories',
  H2_CATEGORIES: 'h2categories',
  H3_CATEGORIES: 'h3categories',
  NUTRITION: 'nutrition',
  DIETS: 'diets',
}

export const UNIVERSAL_SEARCH_FACETS = {
  BRAND: 'brand_name',
  STORE: 'store_id',
}

export const SORT_BY_FACET = {
  id: 0,
  items: [],
  name: 'Sort by',
  facet: SEARCH_FILTER_TYPE.SORT_BY,
}

export enum CATEGORY_HEADER_TYPE {
  MORE = 'More',
  DEALS = 'Deals',
  BUY_AGAIN = 'Buy again',
  STORE_PRICING = 'Store pricing',
  LISTS = 'Lists',
  CATEGORIES = 'Categories',
  DELIVERY_WINDOW = 'Delivery window',
  STORE_SELECTOR = 'Store selector',
  PRICING_POLICY = 'No markups, no hidden fees*',
}

export enum BACKUP_PREFERENCE_SELECTION {
  CONTACT_ME = 'Contact me',
  DO_NOT_SUBSTITUTE = 'Do not substitute',
  GET_A_BACKUP_ITEM = 'Get a backup item',
  NULL = 'null',
}

export const SUGGESTION_TYPES = {
  STANDARD: 'standard',
  POPULAR: 'popular',
  PERSONALIZED: 'personalized',
  CORRECTION: 'correction',
  PERSONALIZED_RECENT: 'personalized_recent',
  PERSONALIZED_POPULAR: 'personalized_popular',
  IMAGE_SUGGESTION: 'ImageSuggestion',
}

export const HEADER_DEFAULT_Y = 72
export const HEADER_Z_INDEX = 1000

// UserPreferences keys
export const USER_PREFERENCES_KEYS = {
  VIEW_REPORT_ISSUE_EDUCATION_MODAL: 'viewedReportIssueEducationModal',
  VIEW_SELECT_STORE_MODAL: 'viewedSelectStoreModal',
  VIEW_POORSUBS_EDUCATION_MODAL: 'viewedPoorSubsEducationModal',
  VIEW_MEMBERSHIP_PROMO_MODAL: 'viewedMembershipPromoModal',
  VIEW_CHECKOUT_MEMBERSHIP_POPUP_MODAL: 'viewedCheckoutMembershipPopupModal',
  VIEW_NOTE_TOOLTIP: 'viewedProductNoteTooltip',
  VIEW_TARGET_RED_CARD_MODAL: 'viewedTargetRedCardModal',
  VIEW_SNAP_EBT_ENTRY_MODAL: 'viewedSnapEBTEntryModal',
  CIRCLE_360_PREFERRED_BANNER: 'viewedCircle360PreferredBanner',
  VIEW_APP_DOWNLOAD_BANNER: 'viewedAppDownloadBanner',
}

export const HEALTH_CARE_EXCEPTIONS = `Beer, Wine, Liquor, Chips, Candy & Chocolate, Soft Drinks, Ice Cream & Dessert, Cakes, pies & desserts, Baby & Toddler items, Toys, Entertainment, Electronics, Beauty, Patio & Garden, School & office supplies, Home goods, Gift & party, Sports & outdoor, Pets, Kitchen & dining`

export const PURCHASE_HISTORY_PAGE_SIZE = 48

export enum GuestDataStore {
  ADDRESS = 'guest_user_address',
  STORE = 'guest_user_store',
}

export const Z_INDEX_MAX = 2147483647
export const Z_INDEX_MODAL = Z_INDEX_MAX - 1
