import { type QueryFunctionContext } from '@tanstack/react-query'
import { apiGet } from '@/utils/dataFetching'
import { type CheckUserEmailResponse, type User } from '@/services/User/types'
import { type UserQueryKey } from '@/services/User/queries'
import { API_URL } from '@/utils/dataFetching/apiUrl'
import { getStoreLocationId, mapGqlUserToUser } from '@/services/User/utils'
import { useBaseUserQuery } from '@/services/User/graphql/BaseUser.generated'
import Bugsnag from '@bugsnag/js'

export const fetchUser = async (
  context: QueryFunctionContext<UserQueryKey>
): Promise<User> => {
  const response = await apiGet<User>({
    config: { url: 'api/v1/customer.json' },
    options: { apiUrl: API_URL.MEMBER_API },
    context,
    fetcherName: 'fetchUser',
  })
  const storeLocationId = getStoreLocationId(response)
  const user = {
    ...response,
    ...(storeLocationId && { store_location_id: storeLocationId }),
  }
  return user
}

export const fetchUserGraphqlQuery = async (
  context: QueryFunctionContext
): Promise<User> => {
  const data = await useBaseUserQuery.fetcher()(context)

  if (!data.customer) {
    Bugsnag.leaveBreadcrumb('Debug: no customer found error', { data })
    throw new Error('customer is not found')
  }

  return mapGqlUserToUser(data.customer)
}

export const checkUserEmail = (email: string) => {
  return apiGet<CheckUserEmailResponse>({
    config: {
      url: 'api/v1/customers/check.json',
      params: { email },
    },

    options: { apiUrl: API_URL.MEMBER_API },
    fetcherName: 'checkUserEmail',
  })
}
