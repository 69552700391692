import { atom } from 'jotai'
import {
  type CheckoutPreferences,
  type SelectedPaymentMethod,
  type CvcTokensByPaymentId,
  type DeliveryTime,
} from '@/state/CheckoutPreferences/types'

export const initialCheckoutPrefs: CheckoutPreferences = {
  paymentMethods: [],
  promoCode: '',
  cvcTokensByPaymentId: {},
  deliveryTime: null,
  earlyDelivery: null,
}

export const checkoutPreferencesAtom = atom(initialCheckoutPrefs)
checkoutPreferencesAtom.debugLabel = 'checkoutPreferencesAtom'

export const selectedPaymentMethodsAtom = atom<
  CheckoutPreferences['paymentMethods'],
  Array<SelectedPaymentMethod[]>,
  void
>(
  (get) => get(checkoutPreferencesAtom).paymentMethods,
  (_, set, newPaymentMethods) => {
    set(checkoutPreferencesAtom, (prevPreferences) => ({
      ...prevPreferences,
      paymentMethods: newPaymentMethods.map((paymentMethod) => ({
        ...paymentMethod,
        ...(paymentMethod.option === 'credit_card' && {
          external_id: paymentMethod.id?.toString(),
        }),
      })),
    }))
  }
)
selectedPaymentMethodsAtom.debugLabel = 'selectedPaymentMethodsAtom'

export const promoCodeAtom = atom<
  CheckoutPreferences['promoCode'],
  Array<string>,
  void
>(
  (get) => get(checkoutPreferencesAtom).promoCode,
  (_, set, newPromoCode) => {
    set(checkoutPreferencesAtom, (prevPreferences) => ({
      ...prevPreferences,
      promoCode: newPromoCode,
    }))
  }
)
promoCodeAtom.debugLabel = 'promoCodeAtom'

export const cvcTokensByPaymentIdAtom = atom<
  CheckoutPreferences['cvcTokensByPaymentId'],
  Array<CvcTokensByPaymentId>,
  void
>(
  (get) => get(checkoutPreferencesAtom).cvcTokensByPaymentId,
  (_, set, newCvcTokensByPaymentId) => {
    set(checkoutPreferencesAtom, (prevPreferences) => ({
      ...prevPreferences,
      cvcTokensByPaymentId: newCvcTokensByPaymentId,
    }))
  }
)
cvcTokensByPaymentIdAtom.debugLabel = 'cvcTokensByPaymentIdAtom'

export const deliveryTimeAtom = atom<
  CheckoutPreferences['deliveryTime'],
  Array<DeliveryTime>,
  void
>(
  (get) => get(checkoutPreferencesAtom).deliveryTime,
  (_, set, newDeliveryTime) => {
    set(checkoutPreferencesAtom, (prevPreferences) => ({
      ...prevPreferences,
      deliveryTime: newDeliveryTime,
    }))
  }
)
deliveryTimeAtom.debugLabel = 'deliveryTimeAtom'

export const earlyDeliveryAtom = atom<
  CheckoutPreferences['earlyDelivery'],
  Array<boolean | null>,
  void
>(
  (get) => get(checkoutPreferencesAtom).earlyDelivery,
  (_, set, newEarlyDelivery) => {
    set(checkoutPreferencesAtom, (prevPreferences) => ({
      ...prevPreferences,
      earlyDelivery: newEarlyDelivery,
    }))
  }
)
earlyDeliveryAtom.debugLabel = 'earlyDeliveryAtom'
